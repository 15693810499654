.row div.donut-container {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
}

.donut-hole-text {
    text-align: center;
    position: absolute;
    margin-top: calc(10% + 30px);
    width: 46%;
    max-width:150px;
    max-height: 32%;
}

.color-box {
    width: 16px;
    height: 16px;
    border-radius: 4px
}

.fat-number {
    font-size: 20px;
    font-weight: 700;
}

.invested-number {
    font-weight: 600;
}

.projects-list {
    font-size: 15px;
}