.breadcrumbs {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 20px !important;
    color: #364E63 !important;
    display: flex;
    align-items: center;
    margin-bottom: 13px;
}

.breadcrumbs .main {
    margin-right: 8px !important;
}

.breadcrumbs .sepz {
    margin-right: 8px;
    width: 8px;
    height: 13px;
}

.card {
    border: 1px solid #D9D9D9 !important;
    border-radius: 6px !important;
}

.card-header {
    border: none !important;
}

.main-card-header {
    width: 100% !important;
}

.main-card-header h1 {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 32px !important;
    color: #364E63 !important;
}

.main-card-header h6 {
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 32px !important;
    color: #364E63 !important;
}

.project-details-card-wrapper {
    padding: 30px;
}

.project-details-card-wrapper h1 {
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 32px !important;
    color: #364E63 !important;
}

.project-details-card-wrapper .btn-collapse {
    display: none;
}

.project-details-card-wrapper h6 {
    margin-top: 0;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 26px !important;
    color: #364E63 !important;
}

.project-details-card-wrapper.card-body {
    padding-top: 0 !important;
    /* margin-top: 0px !important; */
}

button.btn-social {
    padding: 0;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    border: 1px solid #D5DFE7;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #364E63;
}

.btn-social.fb {
    position: absolute;
    top: 23px;
    right: 86px;
}

.btn-social.twt {
    position: absolute;
    top: 23px;
    right: 34px;
}

.btn-carousel {
    position: absolute;
    bottom: 56px;
    right: 54px;
    z-index: 2;
}

.btn-carousel.left {
    position: absolute;
    bottom: 56px;
    right: 88px;
    margin: 0;
}

.project-specs-wrapper {
    height: auto !important;
    width: 100%;
    flex-grow: 1 !important;
}

.project-specs-wrapper .proj-specs-value {
    word-break: break-all;
    color: #364E63;
    text-align: end;
}

.project-specs-wrapper h1 {
    padding-left: 30px;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: #364E63 !important;
}

.project-specs-wrapper .card-header {
    padding: 0;
    padding-top: 35px;
}

.project-details-card-wrapper.collapsed {
    padding-bottom: 10px;
}

.proj-specs-container {
    position: sticky;
    top: 13%;
    display: flex !important;
}

.not-sticky {
    position: inherit !important;
    top: 0%;
}

.card-footer {
    border-top: none !important;
}

.btn-invest {
    width: 100%;
    border-radius: 6px;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 20px !important;
    color: white;
    margin-bottom: 10px;
}

.not-connected-wrapper {
    width: 100% !important;
    padding: 18px !important;
    margin-bottom: 20px;
}

.not-connected-wrapper h3 {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 32px !important;
    color: #364E63 !important;
    margin-bottom: 18px !important;
}

.not-connected-wrapper .btn-connect {
    border: none !important;
    width: 100% !important;
    background-color: white;
}

.border-left {
    border-left: 1px solid #D5DFE7 !important;
}

.border-bottom {
    border-left: 1px solid #D5DFE7 !important;
}

.swot-wrapper {
    width: 100% !important;
    display: flex;
    min-width: 0;
}

.swot-wrapper .swot-cell {
    width: 50%;
    padding: 25px 15px 45px 30px;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 26px !important;
    color: #364E63 !important;

}

.swot-cell ul {
    list-style: none;
}

.swot-cell ul li::before {
    /* \2022 is the CSS Code/unicode for a bullet */
    content: "\2022";
    font-weight: bold;
    display: inline-block;
    width: 1.3em;
    margin-left: -1.3em;
}

.swot-cell .title {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 32px !important;
    color: #6F869B;
}

.swot-cell.strenghts {
    border-right: 1px solid #D5DFE7;
    border-bottom: 1px solid #D5DFE7;
}

.swot-cell.weaknesses {
    border-bottom: 1px solid #D5DFE7;
}

.swot-cell.opportunities {
    border-right: 1px solid #D5DFE7;
}

div.refracto-rating-disclaimer {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 26px;
    color: #6F869B;
    margin-top: 25px;
}


thead {
    background: transparent !important;
}

thead tr th {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 32px !important;
    color: #6F869B !important;
}

.last-row td {
    border-bottom: none;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
    color: #364E63;
}

tbody tr td {
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: #364E63;
}

.project-details-header-card {
    padding: 30px;
}

.fb-sm, .twt-sm {
    display: none !important;
}

.project-specs-floating-panel-mobile {
    display: none !important;
}

@media only screen and (max-width: 1024px) {
    .project-specs-floating-panel {
        display: none !important;
    }

    .project-specs-floating-panel-mobile {
        display: block !important;
    }

    .project-specs-wrapper {
        margin: 0;
        max-width: 100%;
    }

    .project-specs-list {
        padding: 30px !important;
    }

    .main-card-header .btn-social.fb, .btn-social.twt, .breadcrumbs {
        display: none;
    }

    .fb-sm {
        display: block !important;
        margin-top: 20px;
    }

    .twt-sm {
        display: block !important;
        margin-top: 20px;
        margin-left: 15px;
    }

    .btn-carousel {
        position: absolute;
        bottom: 45px;
        right: 25px;
        z-index: 2;
    }

    .btn-carousel.left {
        position: absolute;
        bottom: 45px;
        right: 60px;
        margin: 0;
    }

    .project-details-card-wrapper {
        padding: 15px;
    }

    .project-details-card-wrapper .card-header {
        display: flex;
        justify-content: space-between;
        align-items: start;
    }

    .project-details-card-wrapper h1 {
        font-size: 18px !important;
    }

    .project-details-header-card {
        padding: 15px;
    }

    .project-details-card-wrapper .btn-collapse {
        display: block;
    }
}

#img-slider img {
    max-height: 400px !important;
}