.qr-code {
    max-width: 50% !important;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.mobile-only {
    display: none;
}

@media (max-width: 1024px) {
    .mobile-only {
        display: block;
    }
}

.existing-pairing {
    cursor: pointer;
    display: flex;
    padding: 10px;
    align-items: center;
    padding: 20px;
}

.existing-pairing img {
    max-width: 25% !important;
}

.existing-pairing:hover {
    background-color: lightgray;
    transition: 0.3s;
}

.existing-pairing:active {
    background-color: gray;
}

.existing-pairing .confirmation-message {
    width: 100%;
    text-align: center;
    padding: 15px;
}