.expand-footer-spacings {
  padding: 0;
  margin: 0;
  border-top: 0;
}

.expand-footer-text {
  padding: 15px;
  margin-right: 10px;
  cursor: pointer;
}