.search-bar-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-icon {
    background-color: white !important;
    border-right: 0 !important;
}

.search-bar-input {
    border-left: 0 !important;
    z-index: 2 !important;
}

.filter-box-buttons .dropdown-toggle,
.filter-box-buttons .dropdown {
    font-weight: 400 !important;
    font-size: 12px !important;
    font-style: normal !important;
}

.filter-card-wrapper {
    border: 1px solid #D9D9D9 !important;
    height: auto !important;
    margin-bottom: 11px !important;
}

.applied-filter {
    background-color: #6853E8;
    border: 1px solid #5A45DC;
    color: white;
    border-radius: 6px;
    padding: 4px 5px 4px 14px;
    display: inline-block;
    margin-right: 10px;
}

.applied-filter span {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    padding: 6px 5px;
}

.applied-filters-container {
    min-width: 60px;
}


.filter-box-buttons {
    max-width: 50%;
    gap: 10px;
}

.filter-box-settings-btn {
    display: none !important;
}

.filter-box-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.filter-box-settings-btn .btn-settings {
    background: #FFFFFF;
    border: 1px solid #D5DFE7;
    border-radius: 6px;
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 1200px) {

    .filter-box-buttons .filter-btn.dsk {
        display: none !important;
    }

    .filter-box-buttons .filter-btn {
        display: none !important;
    }

    .mbl-filters-modal-wrapper .filter-btn {
        display: block !important;
    }

    .filter-box-settings-btn {
        display: block !important;
    }

    .applied-filter {
        margin: 5px;
    }
}