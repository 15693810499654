/* Styles for the slider component */
.slider {
    height: 10px;
    width: 100%;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.custom-track {
    height: 3px;
    margin-top:8px;
}

.custom-track-0, .custom-track-2 {
    background-color: #D5DFE7
}
.custom-track-1 {
    background-color: #6853E8
}

/* Styles for the slider thumb */
.slider .thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #6853E8;
    cursor: grab;
    margin-bottom: 10px;
}

/* Styles for the slider active state */
.slider .thumb.active {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.slider-container {
    /* background-color: white; */
    padding: 10px;
    min-width: 200px;
}

.slider-header {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
}