.transactions-table {
    height: 50vh;
}

.transactions-table thead {
    position: sticky;
    top: 0;
    background-color: white !important;
    color: var(--blugrey-4, #364E63);
    font-size: 12px;
    /* font-family: Inter; */
    font-weight: 700;
    line-height: 20px;
}

.transactions-table .date {
    min-width: 110px;
}

.transactions-table td, th {
    color: var(--blugrey-4, #364E63);
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
}

.transactions-table .status::before {
    content: '•';
    display: inline-block;
    font-size: 2em;
    vertical-align: middle;
    /* Add this property to center vertically */
    margin-right: 4px;
    /* line-height: 0.5em; */
    /* margin-left: -1.5em; */
}

.transactions-table .status-pending::before {
    color: #EBBC15;
}

.transactions-table .status-finished::before {
    color: var(--green-low, #75BE48);
}

.transactions-table .status-cancelled::before {
    color: red;
}

.transactions-table .td-center {
    display: flex;
    justify-content: center;
}

.btn-export {
    padding: 6px 34px !important;
    font-size: 12px !important;
}

.footer-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-wrapper .col-stats, .col-actions {
    display: flex;
    justify-content: space-between;
    width: 25%;
}

.footer-wrapper .col-actions {
    width: auto;
}

.footer-wrapper .btn-outline-primary {
    margin-right: 15px;
}

@media only screen and (max-width: 1024px) {
    .footer-wrapper {
        width: 100%;
        flex-direction: column;
    }

    .footer-wrapper .col-stats {
        width: 100%;
        flex-direction: column;
        align-items: center;
        row-gap: 10px;
        margin-bottom: 10px;
    }

    .footer-wrapper .col-actions {
        width: 100%;
        flex-direction: column;
        align-items: center;
        row-gap: 10px;
        margin-bottom: 10px;
    }

    .btn-export {
        width: 100%;
    }
    .footer-wrapper .btn-outline-primary {
        margin-right: 0px;
    }
}