.square {
    height: 16px;
    width: 16px;
    border-radius: 4px;
}

.view-proj-btn {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    font-weight: 400 !important;
}

.thumb {
    height: auto;
    width: auto;
    max-height: 75px;
    max-width: 75px;
    border-radius: 4px;
}